<script setup lang="ts">
import { onMounted, computed, ref, inject, Ref } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { initMixpanel, trackEvent } from "@two-ui/mixpanel";

import {
  CustomerData,
  ECodeData,
  ProductData,
  RedemptionData,
  RedemptionResponse,
} from "../../api.generated/scion";
import {
  Collapsible,
  FAQs,
  HowTo,
  SpendDetails,
  Support,
  ErrorMessage,
  Footer,
} from "../../components";
import { getSpendDetails } from "../../services/redemption";
import { LoadingSpinner } from "@wegift/two-components";
import Markdown from "markdown-it";
import "@wegift/company-brand-assets/dist/design-system/css/font-f37Lineca.css";
import { fetchTranslationMessages, I18nMessages } from "../../i18n-messages";
import { OrderItemCustomisation } from "../../api.generated/scion/models/OrderItemCustomisation";

const props = defineProps<{
  token: string;
  // The customisation parameter is coming from the Wallet app
  customisation: OrderItemCustomisation | null;
}>();

const route = useRoute();
const { locale, setLocaleMessage } = useI18n();
const redemptionDetails = ref<RedemptionResponse>();
const isLoading = ref<boolean>(true);
const hasFailedLoadingRedemptionDetails = ref<boolean>(false);
const isExternalURL = ref<boolean>(true);

const redemptionInformation = computed(() => {
  if (
    redemptionDetails.value &&
    redemptionDetails.value.redemptionInformation
  ) {
    if (props.customisation) {
      redemptionDetails.value.redemptionInformation.customisation =
        props.customisation;
    }
    const newBackgroundColor =
      redemptionDetails.value?.redemptionInformation?.customisation
        ?.backgroundColour;
    if (newBackgroundColor) {
      document.documentElement.style.setProperty(
        "--bg-color-spend",
        newBackgroundColor
      );
    }
  }
  return redemptionDetails.value?.redemptionInformation as RedemptionData;
});
const eCode = computed(
  () => redemptionDetails.value?.redemptionInformation?.eCode as ECodeData
);
const customer = computed(
  () => redemptionDetails.value?.redemptionInformation?.customer as CustomerData
);
const product = computed(
  () => redemptionDetails.value?.redemptionInformation?.product as ProductData
);
const support = computed(
  () => redemptionDetails.value?.redemptionInformation?.support
);
const hasSupportInfo = computed(
  () => support.value && (support.value.phone || support.value.url)
);

function setLocale(languageCode: string = "en") {
  locale.value = (route?.query?.locale as string) || languageCode;
}

onMounted(async () => {
  const defaultLanguageCode = "en";
  let languageCode = defaultLanguageCode;
  const exp = (route?.query?.exp as string) || null;

  try {
    redemptionDetails.value = await getSpendDetails(props.token, exp);

    languageCode =
      redemptionDetails.value?.redemptionInformation?.product?.languageCode ||
      defaultLanguageCode;

    hasFailedLoadingRedemptionDetails.value =
      redemptionDetails?.value?.status !== "SUCCESS";

    const externalUrl =
      redemptionDetails.value?.redemptionInformation?.externalRedemptionUrl;

    if (!!externalUrl) {
      isExternalURL.value = true;
      window.location.href = externalUrl;
      return;
    } else {
      isExternalURL.value = false;
      trackEvent('Network: User navigates to the "Payout Link" page.', {
        customerId: customer.value?.hashedId,
      });
    }
  } catch (e) {
    hasFailedLoadingRedemptionDetails.value = true;
    console.error(e);
  }

  try {
    const translationsResult = await fetchTranslationMessages();
    const messages = translationsResult || ({} as I18nMessages);

    Object.keys(messages).forEach((locale) => {
      setLocaleMessage(locale, messages[locale]);
    });

    setLocale(languageCode);
  } catch (e) {
    hasFailedLoadingRedemptionDetails.value = true;
    isLoading.value = false;
    console.error(e);
    return;
  }

  isLoading.value = false;
});

const onTnCMounted = () => {
  if (
    redemptionDetails.value &&
    redemptionDetails.value.redemptionInformation?.product?.terms
  ) {
    const md = new Markdown();
    const howTo = md.render(
      redemptionDetails.value.redemptionInformation?.product?.terms
    );
    const contentContainer = document.getElementById("tncs");

    if (contentContainer) {
      contentContainer.innerHTML = howTo;
    }
  }
};

initMixpanel();
</script>

<template>
  <div v-if="isLoading" class="flex min-h-full flex-1">
    <LoadingSpinner class="m-auto flex-1" />
  </div>

  <div v-else-if="hasFailedLoadingRedemptionDetails">
    <ErrorMessage :errorCode="redemptionDetails?.errorCode" />
  </div>

  <div v-else-if="!isExternalURL">
    <section
      title="Redemption information"
      class="font-f37lineca mb-4 rounded-2xl bg-white p-6"
    >
      <SpendDetails :redemptionData="redemptionInformation"> </SpendDetails>
    </section>

    <section
      v-if="product?.redeemInstructions"
      :title="$t('message.how_to_use_your_gift_card')"
      id="how-to"
      class="font-f37lineca mb-4 rounded-2xl bg-white p-6"
    >
      <HowTo :redeemInstructions="product.redeemInstructions"> </HowTo>
    </section>

    <section
      :title="$t('message.faq_and_support')"
      :class="[
        'font-f37lineca mb-4 rounded-2xl bg-white',
        !product?.faqs?.length && !hasSupportInfo && 'hidden',
      ]"
    >
      <FAQs
        v-if="product?.faqs?.length"
        class="border-b border-gray-200 last:border-b-0"
        :faqs="product?.faqs"
        :primaryColour="
          redemptionInformation.customisation?.primaryColour || 'black'
        "
      >
      </FAQs>

      <Support
        v-if="support && hasSupportInfo"
        :product="product"
        :support="support"
        :primaryColour="
          redemptionInformation.customisation?.primaryColour || 'black'
        "
      ></Support>
    </section>

    <section
      v-if="product?.terms"
      :title="$t('message.terms_and_conditions')"
      class="font-f37lineca mb-4 rounded-2xl bg-white p-6"
    >
      <Collapsible
        :title="$t('message.terms_and_conditions')"
        @vue:mounted="onTnCMounted"
      >
        <div id="tncs"></div>
      </Collapsible>
    </section>

    <Footer
      :e-code-data="eCode"
      :senderName="
        redemptionInformation.customisation?.senderName || customer?.name
      "
      :product="product"
    />
  </div>
</template>

<style scoped></style>
