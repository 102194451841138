<script setup lang="ts">
import { computed } from "vue";
import { ref } from "@vue/reactivity";
import { trackEvent } from "@two-ui/mixpanel";
import {
  BarcodeGraphic,
  CurrencyEnum,
  ECodeData,
  ProductData,
  RedemptionData,
  WhitelabelData,
  CustomerData,
} from "../../api.generated/scion";
import { GiftCard } from "../index";
import { getCurrencySymbol } from "../../util/currency";
import { getDaysRemaining } from "../../util/date";
import { CODE_TOKEN, customRedeemURLs } from "../../util/product";
import { OrderItemCustomisation } from "../../api.generated/scion/models/OrderItemCustomisation";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const ENABLE_BLURRY_CODE_FULFILMENT = import.meta.env
  .VITE_ENABLE_BLURRY_CODE_FULFILMENT;
const EXCLUDE_BLURRY_CODE_FULFILMENT_CUSTOMERS =
  import.meta.env.VITE_EXCLUDE_BLURRY_CODE_FULFILMENT_CUSTOMERS.split(",");

const props = defineProps<{
  redemptionData: RedemptionData;
}>();

const currency = ref(props.redemptionData.currency as CurrencyEnum);
const product = ref(props.redemptionData.product as ProductData);
const whitelabel = ref(props.redemptionData.whitelabel as WhitelabelData);
const eCode = ref(props.redemptionData.eCode as ECodeData);
const barcodeGraphic = ref(props.redemptionData.barcodeGraphic);
const customization = ref(
  props.redemptionData.customisation as OrderItemCustomisation
);
const customer = ref(props.redemptionData.customer as CustomerData);
const displayBlurryCodesToggle = ref(true);

function print() {
  window.print();
}

function onShowCodesCTA() {
  displayBlurryCodesToggle.value = false;
  trackEvent('Network: User clicks the "Show codes" CTA.', {
    customerId: customer.value?.hashedId,
  });
}

const customRedeemURL = computed(() => {
  const customURL = customRedeemURLs[product.value.code];

  if (!customURL) return;

  return customURL.replace(CODE_TOKEN, eCode.value.code as string);
});

const formattedExpiryDate = computed(() => {
  const expiryDate = new Date(eCode.value.expiryDate);
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  return expiryDate.toLocaleDateString(userLocale, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
});

const displayBlurryCodes = computed(() => {
  if (ENABLE_BLURRY_CODE_FULFILMENT !== "true") {
    return false;
  }

  if (
    customer.value?.hashedId &&
    EXCLUDE_BLURRY_CODE_FULFILMENT_CUSTOMERS.includes(customer.value.hashedId)
  ) {
    return false;
  }

  if (customization.value !== null) {
    return false;
  }

  if (!displayBlurryCodesToggle.value) {
    return false;
  }

  return true;
});
</script>

<template>
  <div class="m-auto flex max-w-md flex-col items-center">
    <img
      v-if="customization?.logoUrl"
      class="mb-4 rounded-2xl"
      :src="customization?.logoUrl"
      alt="Custom logo"
    />

    <h1 class="mb-4 text-center text-3xl font-semibold">
      {{ $t("message.gift_card", { msg: product.displayName }) }}
    </h1>

    <span class="mb-5 rounded-xl bg-grey-300 px-4 py-1 text-lg">
      {{ getCurrencySymbol(currency) }}{{ eCode.value }}
    </span>

    <img
      class="mb-4 max-h-40 rounded-2xl"
      :src="whitelabel.cardImageUrl"
      alt="Gift Card banner"
    />

    <div class="mb-4 w-full rounded-2xl bg-grey-300 px-4 py-6 pb-0">
      <!-- TODO: Check if it's a POS product, otherwise render the Regular ECode component -->
      <img
        v-if="displayBlurryCodes"
        src="@workspace/assets/images/payout-link-blurred-code.png"
        alt="blurry-code"
      />
      <GiftCard
        v-else
        :eCode="eCode"
        :product="product"
        :barcodeGraphic="barcodeGraphic"
      ></GiftCard>
    </div>
    <p
      v-if="eCode.expiryDate"
      class="mb-4 w-full text-center text-sm font-normal"
      data-testid="code-expiration-message"
    >
      {{
        $t("message.expiry_date", {
          time: getDaysRemaining(eCode.expiryDate),
          days: formattedExpiryDate,
        })
      }}
    </p>

    <div class="mb-4 flex w-full print:hidden">
      <button
        v-if="displayBlurryCodes"
        @click="onShowCodesCTA"
        target="_blank"
        class="flex-1 rounded-xl p-6 text-center text-white drop-shadow-md hover:drop-shadow-lg"
        :style="{
          backgroundColor:
            redemptionData.customisation?.primaryColour || 'black',
        }"
        data-testId="show-codes-cta"
      >
        <span>{{ $t("message.show_codes") }}</span>
      </button>
      <a
        v-else
        :href="customRedeemURL || whitelabel.brandUrl"
        target="_blank"
        class="flex-1 rounded-xl p-6 text-center text-white drop-shadow-md hover:drop-shadow-lg"
        :style="{
          backgroundColor:
            redemptionData.customisation?.primaryColour || 'black',
        }"
      >
        <span v-if="customRedeemURL">{{ $t("message.apply_to_account") }}</span>
        <span v-else>{{ $t("message.redeem_online") }}</span>
      </a>

      <button
        @click="print"
        class="ml-3 rounded-xl bg-grey-app p-4 md:p-6"
        type="button"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 9V2H18V9"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6 18H4C3.46957 18 2.96086 17.7893 2.58579 17.4142C2.21071 17.0391 2 16.5304 2 16V11C2 10.4696 2.21071 9.96086 2.58579 9.58579C2.96086 9.21071 3.46957 9 4 9H20C20.5304 9 21.0391 9.21071 21.4142 9.58579C21.7893 9.96086 22 10.4696 22 11V16C22 16.5304 21.7893 17.0391 21.4142 17.4142C21.0391 17.7893 20.5304 18 20 18H18"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6 14H18V22H6V14Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>

    <a href="#how-to" class="font-semibold print:hidden">
      {{ $t("message.how_to_use_my_gift_card") }}
    </a>
  </div>
  <div
    v-if="displayBlurryCodes"
    class="mb-2 mt-4 flex w-full rounded-2xl bg-grey-300 p-4"
  >
    <font-awesome-icon
      class="my-2 ml-2 text-black"
      :icon="['far', 'circle-info']"
      size="xl"
    />
    <p class="my-2 pl-6 font-normal">
      To keep your gift card safe, it is recommended that you only show the gift
      card details when you are ready to spend.
    </p>
  </div>
</template>

<style scoped>
.shawdow-redeem {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.13), 0px 9px 19px rgba(0, 0, 0, 0.3);
}
</style>
