import {
  ApiError,
  RedemptionResponse,
  WalletService,
} from "../api.generated/scion";
import { OpenAPI } from "../api.generated/scion";

export async function getSpendDetails(
  token: string,
  exp: string | null
): Promise<RedemptionResponse | undefined> {
  const commaSeparatedBaseUrls = import.meta.env.VITE_SCION_BASE_URL as string;
  const listOfBaseUrls = commaSeparatedBaseUrls.split(",");

  for (const index in listOfBaseUrls) {
    try {
      // Do not remove the `await` from here. We want to catch the error
      // inside this method. Check the comment block below on why.
      OpenAPI.BASE = listOfBaseUrls[index];

      const validExp = exp === "1" || exp === "2" ? exp : undefined;
      return await WalletService.redeemECode(token, validExp);
    } catch (e) {
      // If there's an API error with a status code 400 or above, log it and try the next URL
      if ((e as ApiError).status >= 400) {
        console.error(e);
        continue;
      }
      // If there's a different kind of error, throw it to be handled by the caller
      throw e;
    }
  }
}
