/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RedemptionResponse } from "../models/RedemptionResponse";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class WalletService {
  /**
   * Redeem an ECode from a Runa Payout Link
   * @param eCodeTokenCode
   * @param exp
   * @returns RedemptionResponse OK
   * @throws ApiError
   */
  public static redeemECode(
    eCodeTokenCode: string,
    exp?: string
  ): CancelablePromise<RedemptionResponse> {
    return __request({
      method: "GET",
      path: `/internal-service-api/wallet/asset/${eCodeTokenCode}`,
      query: {
        exp: exp,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
}
