<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, onMounted, ref, onUnmounted } from "vue";
import * as bwipjs from "bwip-js";
import {
  BarcodeGraphic,
  ECodeData,
  ECodePINSourceEnum,
  ProductData,
} from "../../../api.generated/scion";
import {
  extraFieldsToDisplay,
  customOnlineCodeLabels,
  numericBarcodeLabels,
  productsToDisplayNumericBarcode,
  productsToHideCardNumber,
} from "../../../util/product";
import { barcodeFormat, barcodeSize } from "../../../util/barcode";

const { t } = useI18n();

let showCopied = ref(false);
let timeoutId = ref();

const props = defineProps<{
  product: ProductData;
  eCode: ECodeData;
  barcodeGraphic?: BarcodeGraphic;
}>();

const codeToDisplay = computed(() => {
  // STUB-US is the only product using a custom code field
  if (props.eCode.code === "STUB-US") {
    return props.eCode.dpSerialNumber;
  }

  return props.eCode.codeSeparated;
});

const extraFields = computed(() => {
  const fieldsToDisplay = extraFieldsToDisplay[props.product.code];

  if (!fieldsToDisplay) return [];

  try {
    const extraFields: { [key: string]: string } = JSON.parse(
      (props.eCode.extraFields || "{}").replace(/'/g, '"')
    );

    return fieldsToDisplay.map((fieldToDisplay) => {
      return [fieldToDisplay.label, extraFields[fieldToDisplay.key]];
    });
  } catch (e) {
    console.error(e);
    return [];
  }
});

const copyToClipboard = () => {
  const el = document.createElement("textarea");
  el.value = props.eCode.code as string;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  showCopied.value = true;
  timeoutId.value = setTimeout(() => {
    showCopied.value = false;
  }, 2000);
};

const codeLabelToDisplay = computed(() => {
  const onlineCodeLabel = customOnlineCodeLabels[props.product.code];
  if (onlineCodeLabel) {
    return onlineCodeLabel;
  }

  return props.product.codeNoun;
});

const displayNumericBarcode = computed(() => {
  return productsToDisplayNumericBarcode.includes(props.product?.code);
});

const numericBarcodeLabelToDisplay = computed(() => {
  const label = numericBarcodeLabels[props.product.code];
  if (label) {
    return label;
  }

  return "";
});

const displayCardNumber = computed(() => {
  return !productsToHideCardNumber.includes(props.product?.code);
});

onMounted(() => {
  if (props.barcodeGraphic) {
    // @ts-ignore
    bwipjs.toCanvas("#barcode", {
      text: props.barcodeGraphic.codeValue,
      bcid: barcodeFormat[props.barcodeGraphic.barcodeFormat],
      width: barcodeSize[props.barcodeGraphic.barcodeFormat][0],
      height: barcodeSize[props.barcodeGraphic.barcodeFormat][1],
      scale: 10,
    });
  }
});

onUnmounted(() => {
  if (timeoutId.value) {
    window.clearTimeout(timeoutId.value);
  }
});
</script>

<template>
  <div class="flex flex-wrap">
    <div v-if="displayCardNumber">
      <div class="mb-6 mr-6 shrink-0">
        <div class="mb-2 text-sm font-semibold">
          {{ codeLabelToDisplay }}
        </div>
        <div class="flex items-center" data-testid="gift-code">
          {{ codeToDisplay }}
          <span class="relative">
            <svg
              @click="copyToClipboard"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="ml-2 w-8 cursor-pointer rounded-lg bg-grey-app p-1 active:bg-grey-app-darker"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
              />
            </svg>
            <span v-if="showCopied" class="transition-opacity">
              <span
                class="absolute left-[-10px] top-[35px] rounded bg-grey-800 px-4 py-[10px] text-xs text-white"
              >
                {{ $t("message.copied") }}
              </span>
              <div
                class="absolute left-[20px] top-[30px] h-[10px] w-[10px] rotate-45 bg-grey-800"
              ></div>
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="mb-6 mr-6 shrink-0 last:mr-0">
      <div class="mb-[12px] text-sm font-semibold">
        <span v-if="eCode.pinSource === ECodePINSourceEnum.PIN">
          {{ product.pinNoun }}
        </span>
        <span v-else-if="eCode.pinSource === ECodePINSourceEnum.CVC2">
          {{ $t("message.cvc2") }}
        </span>
      </div>
      <div>
        <span
          v-if="eCode.pinSource === ECodePINSourceEnum.PIN"
          data-testid="ecode-pin"
        >
          {{ eCode.pin }}
        </span>
        <span
          v-else-if="eCode.pinSource === ECodePINSourceEnum.CVC2"
          data-testid="ecode-cvc2"
        >
          {{ eCode.cvc2 }}
        </span>
      </div>
    </div>

    <div
      v-for="extra in extraFields"
      :key="extra[0]"
      class="mb-6 mr-6 shrink-0 last:mr-0"
    >
      <div class="mb-2 text-sm font-semibold">{{ extra[0] }}</div>
      <div>{{ extra[1] }}</div>
    </div>
  </div>

  <div v-if="displayNumericBarcode">
    <div class="mb-6 mr-6 shrink-0 last:mr-0">
      <div class="mb-[12px] text-sm font-semibold">
        <span>
          {{ numericBarcodeLabelToDisplay }}
        </span>
      </div>
      <div>
        {{ props?.barcodeGraphic?.codeValue }}
      </div>
    </div>
  </div>

  <canvas
    v-if="barcodeGraphic"
    id="barcode"
    class="m-auto mb-6 max-h-28 max-w-full"
    data-testid="barcode-graphic"
  ></canvas>
</template>
